<template>
  <div v-if="mode=='edit'">
    <b-row v-if="render">
      <b-col md="12">
        
        <div class="row" :key="'formRow_' + forceUpdate.row" v-if="formFields && formFields.length">        
          <div v-for="(columns, keyCol) in formFieldsTMP"
                    :key="keyCol"
                    :class="'form-group col-md-' + formGridCols"> 

            <div class="row">
              <div v-for="(element, key) in columns"
                  :key="key"
                  :class="'form-group col-md-' + element.cols">

                <div v-if="element.type == 'string'">
                    <b-form-group :label="element.title">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>                                                        
                </div>
                <div v-if="element.type == 'email'">
                    <b-form-group :label="element.title">
                      <b-form-input type="email"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>     
                </div>   
                <div v-if="element.type == 'text'">
                  <b-form-group :label="element.title">
                    <vue-editor v-model="block.control[element.name]"></vue-editor>              
                  </b-form-group>  
                </div> 
                <div v-if="element.type == 'integer'">
                    <b-form-group :label="element.title">
                      <b-form-input type="number"
                                    step="1"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>   
                </div>            
                <div v-if="element.type == 'decimal'">
                    <b-form-group :label="element.title">
                      <b-form-input type="number"
                                    step="0.01"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>                                                                                                               
                </div>   
                <div v-if="element.type == 'select'">
                    <b-form-group :label="element.title">
                      <b-form-select v-model="block.control[element.name]">
                        <b-form-select-option :value="null"></b-form-select-option>
                        <b-form-select-option v-for="(element1,key1) in element.options.split(',')"
                                              :key="key1"
                                              :value="element1">
                          {{element1}}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>                                                              
                </div>     
                <div v-if="element.type == 'date'">
                    <b-form-group :label="element.title">
                      <b-form-datepicker size="sm" 
                                          v-model="block.control[element.name]" 
                                          local="es">
                      </b-form-datepicker>
                    </b-form-group>              
                </div>  
                <div v-if="element.type == 'checkbox'">     
                    <b-form-checkbox v-model="block.control[element.name]"
                                      switch 
                                      size="sm">
                      <b>{{element.title}}</b>
                    </b-form-checkbox>                                                                                                                          
                </div> 
                <div v-if="element.type == 'image'">
                  <b-form-group :label="element.title" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">
                    <Imagen :images="block.control[element.name]" 
                            @load-image="block.control[element.name] = $event"                              
                            :typeImage="'Imagen_' + dataIndex + '_' + block.control[element.name] "
                            :multipleImage="true"
                            :maxImage="2"                                  
                            :compressImage="true"/>
                  </b-form-group>                          
                </div>   
                <div v-if="element.type == 'forms'">
                    <b-form-group :label="element.title">
                      <b-form-select v-model="block.control[element.name]" :options="arr.select.forms"></b-form-select>
                    </b-form-group>                                                              
                </div>    
                <div v-if="element.type == 'instagram'">
                    <b-form-group :label="element.title">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>                                                        
                </div>      
                <div v-if="element.type == 'color'">
                    <b-form-group :label="element.title">
                      <b-form-input type="color"
                                    size="sm"
                                    v-model="block.control[element.name]">
                      </b-form-input>
                    </b-form-group>                                                        
                </div>                                                     
              </div>
            </div>

          </div>                    
        </div>
        <div v-else>
          <b-alert show variant="warning">
            No hay campos definidos para este bloque
          </b-alert>
        </div>

      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div v-if="render">      
      <b-list-group-item class="flex-column align-items-start">

        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Item {{block.control.name}}</h5>              
        </div>
      
      </b-list-group-item>    
    </div>
  </div>
</template>
<script>
  import Imagen from '@/components/inc/image/image'
  import { VueEditor } from "vue2-editor";
  import serviceAPI from './services'
  import Error from '@/handler/error'

  export default {
    components: {
      VueEditor,
      Imagen,
    },
    props:{
      dataBlock:{
        type: Object,
        default: {}
      },   
      dataIndex:{
        type: Number,
        default: 0,
        required: true,
      },       
      controlTemporal: {
        type: [Object, Array],
      },              
      mode:{
        type: String,
        default: 'list'
      },   
      contentBlock:{
        type: Object,
        default: {}
      },            
    },
    data: () => {
      return {                    
        block: {
          control: [],
        },        
        render: false,        
        formFields: {},
        formFieldsTMP : [],        
        formGridCols: 12,  
        forceUpdate: {
          row: 0,
        },      
        arr: {
          select: {
            forms: []
          }
        }          
      }
    },
    created() {         
      var content = null      
      if(this.contentBlock.content)
        content = JSON.parse(this.contentBlock.content);        
      
      if(!content) {
        this.block.control = {},
        this.block.config = {}
        this.block.images = [],      
        this.block.imagesConfig = [],

        this.emit()
        this.render = true
      }
      else {           
        this.block.control= {}
        this.block.config= {}
        this.block.images = []
        this.block.imagesConfig = []

        this.emit()
        this.render = true
      }
    },
    computed: {
      getControlTermporal() {
        if(!this.controlTemporal) {
          return this.dataBlock
        } else {
          return this.controlTemporal
        }        
      }
    },  
    mounted() {
      this.loadForm()    
      this.obtenerForm()  
    },
    methods: {
      loadForm() {                  
        this.formFields = JSON.parse(this.contentBlock.block.fields);        
        
        // seteo columnas del formulario
        var formsCols = this.contentBlock.block.cols
        switch (formsCols) {
          case 1:
            this.formGridCols = 12
            break;
        
          case 2:
            this.formGridCols = 6
            break;
            
          case 3:
            this.formGridCols = 4
            break;

          default:
            this.formGridCols = 12
            break;
        }

        if(this.formFields && this.formFields.length) {

          // inicializo las columnas del form     
          for (let index = 0; index < formsCols; index++) {
            this.formFieldsTMP[index] = []                   
          }             
          
          var content = null      
          if(this.contentBlock.content)
            content = JSON.parse(this.contentBlock.content);          

          this.formFields.forEach(element => {

            //ubicacion dentro de cada columna del form
            if(element.column == undefined)  {
              this.formFieldsTMP[0].push(element)                    
            } else {
              this.formFieldsTMP[element.column - 1].push(element)
            }                                    

            if(content) {              
              if(element.type!='image') {                
                if(content.control[this.dataIndex])
                  this.block.control[element.name] = content.control[this.dataIndex][element.name]
                else 
                  this.block.control[element.name] = ''
              }              

              if(element.type=='image') {
                this.block.images.push(element.name)

                if(content.control[this.dataIndex]) {
                  var img = []      
                  var image = parseInt(content.control[this.dataIndex][element.name])              
                  if(image) {          
                    for (var i=0; i < image; i++) {                                
                      if(content.control[this.dataIndex][element.name + "_" + i]) {
                        img.push({ path: content.control[this.dataIndex][element.name + "_" + i] })
                      }                      
                    }          
                  }     
                  this.block.control[element.name] = img          
                } else {
                  this.block.control[element.name] = []
                }
              }              
            } else {
              if(element.type!='image') {
                this.block.control[element.name] = ''
              }    

              if(element.type=='image') {                
                this.block.images.push(element.name)
                this.block.control[element.name] = []
              }
            }
          });          
        }  

        this.setForceUpdate('row')
      },      
      setForceUpdate(type) {
        if(type=='row')
          this.forceUpdate.row++
      },      
      obtenerForm() {
        let loader = this.$loading.show();    
        var result = serviceAPI.obtenerForms()

        result.then((response) => {         
          var data = response.data
          
          data.forEach(element => {
            this.arr.select.forms.push({
              value: element.id,
              text: element.name
            })
          });
          
          loader.hide()   
        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });          
      },          
      emit() {        
        this.$emit('get-object', this.block.control) 
        this.$emit('get-object-images', this.block.images) 
        this.$emit('get-object-control-temporal', this.getControlTermporal) 
      },
    }    
  }
</script>